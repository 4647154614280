import React from 'react';
import ClientScripts from "./components/ClientScripts";
import Sandwich from "./components/Sandwich";
import ContactUsButton from "./components/Button/ContactUsButton";
import SubmitButton from "./components/Button/SubmitButton";
import ProductsSlider from "./components/ProductsSlider";
import './styles/splide.min.css';
import './styles/variables.css';
import './styles/typography.css';
import './styles/normalize.css';
import './styles/main.css';
import './styles/home-section.css';
import './styles/about-section.css';
import './styles/our-product-section.css';
import './styles/our-services-section.css';
import './styles/why-we-section.css';
import './styles/client-review-section.css';
import './styles/contact-us-section.css';

function App() {
  return (
    <>
      <header className="BackgroundColorBlack">
        <nav className="FloatingMenuBlock BackgroundColorBlack">
          <div className="FloatingMenu">
            <a className="TypographySubtitle11 ColorWhite" href="#about-ovaflor">About Ovaflor</a>
            <a className="TypographySubtitle11 ColorWhite" href="#our-services">Our services</a>
            <a className="TypographySubtitle11 ColorWhite" href="#lets-cooperate">Why partner with us?</a>
            <a className="TypographySubtitle11 ColorWhite" href="#contact-us">Contact us</a>
          </div>
        </nav>
        <nav className="BackgroundColorBlack">
          <div className="Logo">
            <object data="/img/logo.svg" width="40" height="40">logo</object>
            <span className="TypographyTitle6 ColorWhite">Ovaflor</span>
          </div>
          <div className="MenuBlock">
            <span className="TypographyBody51 ColorWhite">Menu</span>
            <Sandwich />
          </div>
        </nav>
      </header>

      <main>
        <section className="Section HomeSection">
          <div className="Content">
            <div className="TextPaper">
              <h1 className="TypographyTitle1 ColorDark">Accelerate Your Startup&apos;s Success</h1>
              <span className="TypographySubtitle24 ColorDark">
                Welcome to <span className="TypographySubtitle22 ColorOrange">Ovaflor</span>  unleash Your Startup&apos;s
                Potentialwith Fast MVPs and Powerful Licensed Software
              </span>
              <div className="ButtonBlock">
                <ContactUsButton />
              </div>
            </div>
            <div className="Image">
              <img src="/img/illustration1.png" alt="illustration"/>
            </div>
          </div>
          <div className="TransitionImage">
            <img src="/img/transition_1.svg" alt="transition" />
          </div>
        </section>

        <section id="about-ovaflor" className="Section AboutSection">
          <h2 className="TypographyTitle3 ColorOrange">About Ovaflor</h2>
          <div className="Content">
            <div className="Image">
              <img src="/img/illustration2.svg.png" alt="illustration" />
            </div>
            <div className="Text">
        <span className="TypographyBody12 ColorDark">
          Welcome to Ovaflor, where your startup&apos;s dreams evolve into reality. We specialize in two pivotal domains:
          catapulting your ideas into the market with swift Minimum Viable Product (MVP) development and providing
          you with high-performance licensed software tailored exclusively for startups.
        </span>
            </div>
          </div>
          <div className="MetricBlock">
            <div className="MetricPaper BackgroundColorBlack">
              <span className="MetricTitle TypographySubtitle11 ColorWhite">We help real people do more with business</span>
              <div className="MetricItemList">
                <div className="MetricItem">
                  <span className="TypographyTitle12 ColorOrange">700+</span>
                  <span className="TypographyBody31 ColorWhite">successful projects</span>
                </div>
                <div className="MetricItem">
                  <span className="TypographyTitle12 ColorOrange">40+</span>
                  <span className="TypographyBody31 ColorWhite">active projects per year</span>
                </div>
                <div className="MetricItem">
                  <span className="TypographyTitle12 ColorOrange">100+</span>
                  <span className="TypographyBody31 ColorWhite">dedicated professionals</span>
                </div>
              </div>
            </div>
          </div>
          <div className="TransitionImage">
            <img src="/img/transition_2.svg" alt="transition" />
          </div>
        </section>

        <section className="Section OurProductSection">
          <h2 className="TypographyTitle2 ColorOrange">Our latest product</h2>
          <ProductsSlider />
          <div className="TransitionImage">
            <img src="/img/transition_3.svg" alt="transition" />
          </div>

        </section>

        <section id="our-services" className="Section OurServicesSection">
          <h2 className="TypographyTitle2 ColorOrange">Our Services</h2>
          <h3 className="TypographySubtitle12 ColorDark">Fast MVP Development</h3>
          <div className="CardList">
            <div className="CardItem">
              <div className="CardItemPaper">
                <img src="/img/card-prototype-website.png" alt="card" width="154" height="154" />
                <span className="TypographyBody11 ColorOrange">Rapid Prototyping</span>
              </div>
            </div>
            <div className="CardItem">
              <div className="CardItemPaper">
                <img src="/img/card-program-bar.png" alt="card" width="154" height="154" />
                <span className="TypographyBody11 ColorOrange">User-Centric Design</span>
              </div>
            </div>
            <div className="CardItem">
              <div className="CardItemPaper">
                <img src="/img/card-analytics.png" alt="card" width="154" height="154" />
                <span className="TypographyBody11 ColorOrange">Agile Development</span>
              </div>
            </div>
            <div className="CardItem">
              <div className="CardItemPaper">
                <img src="/img/card-browser-window.png" alt="card" width="154" height="154" />
                <span className="TypographyBody11 ColorOrange">Market-Ready Validation</span>
              </div>
            </div>
          </div>
          <div className="BackgroundColorOrange SoftwareSolutionsBlock">
            <h3 className="TypographySubtitle12 ColorWhite">Licensed Software Solutions</h3>
            <div className="SolutionList">
              <div className="SolutionItem">
                <img src="/img/solution-tailored.svg" alt="solution"/>
                <span className="TypographyBody21 ColorWhite">Tailored Software Solutions</span>
              </div>
              <div className="SolutionItem">
                <img src="/img/solution-archi-design.svg" alt="solution" />
                <span className="TypographyBody21 ColorWhite">Scalable Architectural Design</span>
              </div>
              <div className="SolutionItem">
                <img src="/img/solution-support.svg" alt="solution" />
                <span className="TypographyBody21 ColorWhite">Continuous and Adaptive Support</span>
              </div>
              <div className="SolutionItem">
                <img src="/img/solution-strategy.svg" alt="solution" />
                <span className="TypographyBody21 ColorWhite">Strategic Market Positioning</span>
              </div>
            </div>
          </div>
          <div className="TransitionImage">
            <img src="/img/transition_4.svg" alt="transition" />
          </div>
        </section>

        <section id="lets-cooperate" className="Section WhyWeSection">
          <h2 className="TypographyTitle2 ColorOrange">Why Partner with Us?</h2>
          <h3 className="TypographySubtitle23 ColorDark">
            We are more than service providers, we are partners in your startup journey
          </h3>
          <div className="Content">
            <div className="PlanBlock">
              <div className="PlanItem Active">
                <span className="TypographyBody22 ColorDark">Experience</span>
                <span className="Subtitle TypographyBody32 ColorSilver">
                  Years of successfully launching MVPs and software solutions.
                </span>
                <span className="Before TypographyBody31">1</span>
                <span className="After"></span>
              </div>
              <div className="PlanItem">
                <span className="TypographyBody22 ColorDark">Expertise</span>
                <span className="Subtitle TypographyBody32 ColorSilver">
                  A wealth of knowledge in efficiently deploying MVPs and pioneering software innovations.
                </span>
                <span className="Before TypographyBody31">2</span>
                <span className="After"></span>
              </div>
              <div className="PlanItem">
                <span className="TypographyBody22 ColorDark">Agility</span>
                <span className="Subtitle TypographyBody32 ColorSilver">
                  Our adaptive approach for quick MVP iterations and staying at the forefront of software trends.
                </span>
                <span className="Before TypographyBody31">3</span>
                <span className="After"></span>
              </div>
              <div className="PlanItem">
                <span className="TypographyBody22 ColorDark">Customization</span>
                <span className="Subtitle TypographyBody32 ColorSilver">
                  Tailored solutions to fit your startup&apos;s unique DNA and propel it to unmatched heights.
                </span>
                <span className="Before TypographyBody31">4</span>
                <span className="After"></span>
              </div>
              <div className="PlanItem">
                <span className="TypographyBody22 ColorDark">Support</span>
                <span className="Subtitle TypographyBody32 ColorSilver">
                  From inception to implementation, we provide unwavering support for your startup&apos;s journey.
                </span>
                <span className="Before TypographyBody31">5</span>
                <span className="After"></span>
              </div>
            </div>
            <div className="Image">
              <img src="/img/make-idea.png" alt="card" />
            </div>
          </div>
        </section>

        {/*<section className="Section ClientsReviewSection">*/}
        {/*  <h2 className="TypographyTitle2 ColorOrange">WHAT CLIENTS SAYS</h2>*/}
        {/*  <div id="ReviewsSplide" className="spl">*/}
        {/*    <div className="spl__track ">*/}
        {/*      <div className="spl__list Slider">*/}
        {/*        <div className="spl__slide ReviewPaper">*/}
        {/*          <div className="Avatar">*/}
        {/*            <img src="/img/user-avatar.png" alt="user" width="118" height="118" />*/}
        {/*            <object data="/img/review-decor.svg" width="116" height="122"></object>*/}
        {/*          </div>*/}
        {/*          <div className="CommentInfo">*/}
        {/*            <span className="UserName TypographyTitle5">Jay Reinemann</span>*/}
        {/*            <span className="Position TypographyBody62">General Partner</span>*/}
        {/*            <hr />*/}
        {/*            <span className="Comment TypographyBody41">*/}
        {/*              This company has become a crucial element in our startup project. They helped us swiftly enter the*/}
        {/*              market with an MVP and provided us with the necessary software for long-term success.*/}
        {/*            </span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="spl__slide ReviewPaper Active">*/}
        {/*          <div className="Avatar">*/}
        {/*            <img src="/img/user-avatar.png" alt="user" width="118" height="118" />*/}
        {/*            <object data="/img/review-decor.svg" width="116" height="122"></object>*/}
        {/*          </div>*/}
        {/*          <div className="CommentInfo">*/}
        {/*            <span className="UserName TypographyTitle5">Jay Reinemann</span>*/}
        {/*            <span className="Position TypographyBody62">General Partner</span>*/}
        {/*            <hr />*/}
        {/*            <span className="Comment TypographyBody41">*/}
        {/*              This company has become a crucial element in our startup project. They helped us swiftly enter the*/}
        {/*              market with an MVP and provided us with the necessary software for long-term success.*/}
        {/*            </span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="spl__slide ReviewPaper">*/}
        {/*          <div className="Avatar">*/}
        {/*            <img src="/img/user-avatar.png" alt="user" width="118" height="118" />*/}
        {/*            <object data="/img/review-decor.svg" width="116" height="122"></object>*/}
        {/*          </div>*/}
        {/*          <div className="CommentInfo">*/}
        {/*            <span className="UserName TypographyTitle5">Jay Reinemann</span>*/}
        {/*            <span className="Position TypographyBody62">General Partner</span>*/}
        {/*            <hr />*/}
        {/*            <span className="Comment TypographyBody41">*/}
        {/*              This company has become a crucial element in our startup project. They helped us swiftly enter the*/}
        {/*              market with an MVP and provided us with the necessary software for long-term success.*/}
        {/*            </span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="SliderControl">*/}
        {/*    <img src="/img/icon-arrow-left.svg" width="32" height="32" alt="icon" />*/}
        {/*    <img src="/img/icon-arrow-right.svg" width="32" height="32" alt="icon" />*/}
        {/*  </div>*/}
        {/*</section>*/}



        <section id="contact-us" className="Section ContactUsSection BackgroundColorOrange">
          <h2 className="TypographyTitle2 ColorWhite">Contact us</h2>
          <h3 className="TypographySubtitle21 ColorWhite">Ready to take your startup to the next level? Let&apos;s talk</h3>
          <div className="Content">
            <div className="LeftColumn">
              <div className="ContactInfoBlock">
                <form id='message_form'>
                  <input type="text" name="name" placeholder="Name" required />
                  <input type="text" name="email" placeholder="Email" required />
                  <input type="text" name="phone" placeholder="Phone" required />
                  <textarea name="message" placeholder="Message" required></textarea>
                  <SubmitButton />
                </form>
                <div className="ContactData">
                  <div className="ContactItem">
                    <span className="TypographyBody31 ColorDark">Our email</span>
                    <span className="TypographyBody41 ColorWhite">contact@ovaflor.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="RightColumn">
              <img src="/img/emails-flying.png" alt="emails" />
            </div>
          </div>
        </section>
      </main>

      <footer className="BackgroundColorBlack">
        <nav>
          <div className="Logo">
            <object data="/img/logo.svg" width="28" height="28">logo</object>
            <span className="TypographyTitle7 ColorWhite ">Ovaflor</span>
          </div>
          <div className="Menu">
            <a className="TypographyBody61 ColorWhite" href="#about-ovaflor">About Ovaflor</a>
            <a className="TypographyBody61 ColorWhite" href="#our-services">Our services</a>
            <a className="TypographyBody61 ColorWhite" href="#lets-cooperate">Why partner with us?</a>
            <a className="TypographyBody61 ColorWhite" href="#contact-us">Contact us</a>
          </div>
          <span className="Copyright TypographyBody71 ColorWhite">©2023, All right reserved.</span>
        </nav>
      </footer>
      <ClientScripts />
    </>
  );
}

export default App;
